import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import AffiliateProgram from '../components/AffiliateProgram'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export const query = graphql`
  {
    allContentfulAffiliateProgram {
      edges {
        node {
          seo {
            internal {
              content
            }
          }
          headingSection {
            internal {
              content
            }
          }
          becomeAnAffiliate {
            internal {
              content
            }
          }
          whatIsAnAffiliate {
            internal {
              content
            }
          }
          faq {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const AffiliateProgramPage = (props) => {
  const state = useContext(GlobalStateContext)
  if (!state.locale) {
    return (
      <Layout url='/affiliate-program' locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  const data = props.data?.allContentfulAffiliateProgram.edges?.filter(
    (node) => node.node.node_locale === state.locale
  )[0].node
  const contentful = {
    headingSection: JSON.parse(data.headingSection.internal.content),
    becomeAnAffiliate: JSON.parse(data.becomeAnAffiliate.internal.content),
    whatIsAnAffiliate: JSON.parse(data.whatIsAnAffiliate.internal.content),
    faq: JSON.parse(data.faq.internal.content),
  }

  return (
    <Layout
      locale={state.locale}
      title={JSON.parse(data?.seo.internal.content).title}
      desc={JSON.parse(data?.seo.internal.content).desc}
      url='/affiliate-program'
    >
      <AffiliateProgram contentful={contentful} />
    </Layout>
  )
}

export default AffiliateProgramPage
