import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import img1 from '../../assets/images/affiliate_program/stock-1.jpg'
import img2 from '../../assets/images/affiliate_program/stock-2.jpg'


const AffiliateProgram = ({ contentful }) => {
    return (
        <div className='affiliate'>
            <div className="affiliate-white">
                <section>
                    <Container className="pt-5">
                        <h1>{contentful.headingSection.title}</h1>
                        <p>
                            {contentful.headingSection.desc.desc1}
                            <strong>{contentful.headingSection.desc.offers[0]}</strong>{' '}
                            {contentful.headingSection.desc.offers[1]}{' '}
                            <strong>{contentful.headingSection.desc.offers[2]}</strong>.{' '}
                            {contentful.headingSection.desc.desc2}
                        </p>
                        <Row className="pt-5">
                            <Col md="3">
                                <button className="sqbutton sqbutton-primary" onClick={() => window.open(contentful.headingSection.buttonLink, '_blank')}>{contentful.headingSection.button}</button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <hr />
                <section>
                    <Container>
                        <Row>
                            <Col md="6">
                                <h2>{contentful.becomeAnAffiliate.title}</h2>
                                <p>{contentful.becomeAnAffiliate.desc}</p>
                            </Col>
                            <Col md="1" />
                            <Col md="5">
                                <div className="affiliate-img-container">
                                    <img src={img1} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <hr />
                <section>
                    <Container>
                        <Row>
                            <Col md="5" className="desktop-only">
                                <div className="affiliate-img-container">
                                    <img src={img2} alt="" />
                                </div>
                            </Col>
                            <Col md="1" />
                            <Col md="6">
                                <div>
                                    <h2>{contentful.whatIsAnAffiliate.title}</h2>
                                    <p>{contentful.whatIsAnAffiliate.desc}</p>
                                </div>
                                <div className="affiliate-subsection">
                                    <h3>{contentful.whatIsAnAffiliate.subtitle}</h3>
                                    <p>{contentful.whatIsAnAffiliate.subdesc}</p>
                                </div>
                            </Col>
                            <Col md="5" className="mobile-only">
                                <div className="affiliate-img-container">
                                    <img src={img2} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <hr />
            <section>
                <div className="affiliate-faq">
                    <Container className="pb-3">
                        <Row className="py-3">
                            <Col md="4"><h2>{contentful.faq.title}</h2></Col>
                            <Col md="8">
                                {contentful.faq.questions.map((v, i) => {
                                    return <div key={i}>
                                        <h3>{v.question}</h3>
                                        {v?.link === "affiliate@heirlumeco.com"
                                            ? <p>{v.answer}{' '}<a href={`mailto:${v.link}`}>{v.link}</a></p>
                                            : v.link
                                                ? <p><a href={v.link} target="_blank" rel="noopener">{v.answer}</a></p>
                                                : <p>{v.answer}</p>}
                                    </div>
                                })}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div >
    )
}

export default AffiliateProgram
